import axios from 'axios';
import has from 'lodash/has';
import moment from 'moment';
import includes from 'lodash/includes';
import toNumber from 'lodash/toNumber';
import {countries, emptyAddress, emptyContact, emptyOtherService} from './variables';
import {getAxios, getLocales, getOrganisations} from '@utilities/api';
import {notifyError, notifySuccess} from "@components/Notification";
import {settings} from "@clientSpecific/utilities/settings";
import toString from "lodash/toString";
import {toInteger} from "lodash/lang";


export const extractJson = function (responseText) {
    const jsonRegex = /```json\n([\s\S]*?)\n```/;
    const match = responseText.match(jsonRegex);
    return match ? match[1] : responseText;
}
export const extractBodyContent = function (htmlString) {
    // Define the regular expression to match content between the specific comment tags
    const regex = /<!-- bodyContentStart -->([\s\S]*?)<!-- bodyContentEnd -->/;
    // Execute the regex match on the input HTML string
    const match = htmlString.match(regex);

    // Check if a match is found and return the trimmed content if so
    if (match && match[1]) {
        return match[1].trim();
    } else {
        // Return null or handle the case as needed when no match is found
        return '';
    }
}

export const replaceBodyContent = function (htmlString, replaceValue) {
    // Define the regular expression to match content between the specific comment tags
    const regex = /<!-- bodyContentStart -->([\s\S]*?)<!-- bodyContentEnd -->/;
    // Replace the matched content with the replaceValue
    return htmlString.replace(regex, `<!-- bodyContentStart -->${replaceValue}<!-- bodyContentEnd -->`);
}

export const availableYears = function (startYear, additionToCurrentYear) {
    const endYear = parseInt(moment().format('YYYY')) + additionToCurrentYear;
    const years = [];
    for(let i = startYear; i <= endYear; i++) {
        years.push(toString(i));
    }
    return years;
};

export const copy = async function (text, successMessage = 'In die Zwischenablage kopiert!') {
    if (text) {
        await navigator.clipboard.writeText(text);
        if(successMessage) {
            notifySuccess(successMessage);
        }
    } else {
        notifyError('kein Element gefunden')
    }
};
export const apiCallWithCancelFactory = function () {
    let call = null;

    return (config = {}) => {
        if (call) {
            call.cancel();
        }
        call = axios.CancelToken.source();

        config.cancelToken = call.token;
        return axios(config);
    };
};
export const getIdFromUrl = function(url) {
    return url.substring(url.lastIndexOf('/') + 1);
};

export const getExchangeRate = function (toCurrencyRate, fromCurrencyRate, dateTime) {
    return new Promise((resolve, reject) => {
        //same: exchangeRate = 1
        if (fromCurrencyRate === toCurrencyRate) {
            resolve(1);
            return;
        }
        let date = moment(dateTime, 'DD.MM.YYYY');
        getAxios('currency_exchanges', {
            toCurrencyCode: toCurrencyRate,
            'startAt[before]': date.format('DD.MM.YYYY'),
            'rates.rate[gt]': 0,
            '_order[endAt]': 'DESC',
            _itemsPerPage: 1
        }).then(response => {
            if (response.data.length === 0) {
                resolve(1);
                return;
            }
            let rate = response.data[0].rates.find(rate => rate.fromCurrencyCode === fromCurrencyRate);
            resolve(rate ? rate.rate : 1);
        }, error => {
            reject(error);
        });
    });
};


/*
    Transforms "2000-05-21T00:00:00+00:00" into "21.05.2000"
*/
export const dateISOtoView = function (ISODate) {
    if (ISODate) {
        const shortDate = ISODate.substring(0, 10),
            dateArray = shortDate.split('-');

        if (dateArray.length > 2) {
            let year      = dateArray[0],
                month     = dateArray[1],
                day       = dateArray[2],
                viewDate  = day + '.' + month + '.' + year;
            return viewDate;
        } else {
            return ISODate;
        }
    } else {
        return '';
    }
};


/*
    Transforms "21.05.2000" into "2000-05-21"
*/
export const dateViewtoISO = function (viewDate) {
    if (viewDate) {
        let shortDate = viewDate.substring(0, 10),
            dateArray = shortDate.split('.'),
            day       = dateArray[0],
            month     = dateArray[1],
            year      = dateArray[2],
            ISODate   = year + '-' + month + '-' + day;

        return ISODate;
    } else {
        return '';
    }
};


// Compare dates
export const compareDates = function (aDate, bDate) {
    const a = dateViewtoISO(aDate).split('-').join('');
    const b = dateViewtoISO(bDate).split('-').join('');

    return a > b ? 1 : -1;
};


// Calculates time between and returns in human readable format - 2h 30m
export const timeBetween = function (date, finalDate) {
    const startAt = moment(date, 'DD.MM.YYYY HH:mm:ss');
    const endAt   = moment(finalDate, 'DD.MM.YYYY HH:mm:ss');

    let minutesBetween = Math.abs(endAt.diff(startAt, 'minutes'));
    const hoursBetween = Math.floor(minutesBetween / 60);
    minutesBetween -= hoursBetween * 60;

    return `${(hoursBetween ? `${hoursBetween}h` : '')} ${(minutesBetween ? `${minutesBetween}m` : '')}`;
};

// Transforms date to "October 2018"
export const monthYear = function (date) {
    return moment(date, 'DD.MM.YYYY HH:mm:ss').format('MMMM YYYY');
};

// Transforms date to local date with time "DD.MM.YYYY HH:mm"
export const dateWithLocalTime = function (date) {
    return moment(date, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm');
};

/*
    Calculates time until a certain date
*/
export const timeUntil = function (date, finalDate = null) {
    let startDate,
        endDate,
        startDateTime,
        endDateTime,
        givenDate = '',
        difference,
        days,
        oneDay = 1000 * 60 * 60 * 24; // ms

    givenDate = new Date(dateViewtoISO(date));

    if (finalDate) {
        // We have a start and an end date
        startDate = givenDate;
        endDate   = new Date(dateViewtoISO(finalDate));
    } else {
        // We count from today till finalDate
        startDate = new Date();
        // Setting it at the begining of the day
        startDate.setHours(0, 0, 0, 0);
        endDate   = givenDate;
    }

    startDateTime = startDate.getTime(); // time in ms from 1 January 1970 00:00:00 UTC
    endDateTime   = endDate.getTime();   // time in ms from 1 January 1970 00:00:00 UTC
    difference    = Math.abs(endDateTime - startDateTime);
    days          = Math.round(difference / oneDay);

    return {
        days: days
    };
};


/*
    Calculates time to deadline and returns the color classes
        red     <= 0days
        yellow  <= 3days
*/
export const deadlineUrgency = function (deadline) {
    if (deadline) {
        const deadlineDate = moment(deadline, 'DD.MM.YYYY HH:mm:ss').startOf('day');
        const todayDate    = moment().startOf('day');
        const days         = deadlineDate.diff(todayDate, 'days');

        if (days <= 0) {
            return 'is-red';
        } else if (days <= 3) {
            return 'is-yellow';
        }
    }

    return '';
};


/*
    Board options in/from localstorage
        - used also in HotelBoard.vue
*/
export const getBoardOptions = function () {
    return new Promise((resolve) => {
        let options = JSON.parse(localStorage.getItem('options'));

        if (options && options.hotel && options.hotel.board && options.lastUpdates && options.lastUpdates.hotel && moment(options.lastUpdates.hotel) >= moment('31.01.2021', 'DD.MM.YYYY')) {
            resolve(options.hotel);
        } else {
            axios.options('/api/hotels').then(response => {
                if (!options) {
                    options       = {};
                }
                if (!options.hotel) {
                    options.hotel = {};
                }
                if (!options.lastUpdates) {
                    options.lastUpdates      = {};
                }

                options.lastUpdates.hotel = moment().startOf('day');
                options.hotel.board         = response.data.board;
                options.hotel.boardExtended = response.data.boardExtended;

                localStorage.setItem('options', JSON.stringify(options));

                resolve(options.hotel);
            });
        }
    });
};


export const getOrderOptions = function () {
    return new Promise((resolve) => {
        let options = JSON.parse(localStorage.getItem('options'));

        if (options && options.order) {
            resolve(options.order);
        } else {
            axios.options('/api/orders').then(response => {
                if (!options) {
                    options = {};
                }

                options.order = response.data;

                localStorage.setItem('options', JSON.stringify(options));

                resolve(options.order);
            });
        }
    });
};


/*
    Calculates nights between dates
        "18.02.2018 00:00:00", "19.02.2018 00:00:00" => 1
*/
export const nights = function (startAt, endAt) {
    const from = moment(startAt.substring(0, 10), 'DD.MM.YYYY');
    const to   = moment(endAt.substring(0, 10),   'DD.MM.YYYY');

    return to.diff(from, 'days');
};

/*
    Calculates the full year
        "18.02.2018 00:00:00" => "18.02.2018"
*/
export const fullYear = function (date) {
    return date ? date.substring(0, 10) : '';
};

/*
    Calculates the full year
        "18.02.2018 00:00:00" => "18.02.2018 00:00 Uhr"
*/
export const fullYearWithTime = function (date) {
    return date ? date.substring(0, 16) + ' Uhr' : '';
};

/*
    Calculates the full year
        "15.10.2018 00:00:00" => "Monday"
*/
export const dayName = function (date) {
    return moment(date, 'DD.MM.YYYY HH:mm:ss').format('dd');
};

/*
    Calculates the day and month
        "18.02.2018 00:00:00" => "18.02"
*/
export const dayMonth = function (date) {
    return date ? date.substring(0, 5) : '';
};


/*
    Converts string to array
        "18.02.2018 to 19.02.2018" => ["18.02.2018", "19.02.2018"]
*/
export const dateRange = function (string) {
    const dateString = string.replace(/\s/g, '');

    if (dateString.indexOf('to') !== -1) {
        const dates = dateString.split('to');
        return dates;
    } else if (dateString.indexOf('bis') !== -1) {
        const dates = dateString.split('bis');
        return dates;
    } else if (dateString.indexOf('-') !== -1) {
        const dates = dateString.split('-');
        return dates;
    }

    return ['', ''];
};


/*
    Calculates the time
        "18.02.2018 09:10:20" => "09:10"
*/
export const time = function (date) {
    if (!date) {
        return '';
    } else if (date.length === 8) {
        return date.substring(0, 5);
    } else if (date.length > 8) {
        return date.substring(11, 16);
    } else {
        return date;
    }
};


/*
    Adds a number of days to the given date
        "18.02.2018 09:10:20" => "19.02.2018 09:10:20"
*/
export const addDays = function (givenDate, nrOfDaysToBeAdded) {
    let date  = dateViewtoISO(givenDate);

    // Create the Date object
    date = new Date(date);
    // We add the number of days to our date
    date.setDate(date.getDate() + nrOfDaysToBeAdded);
    // We convert it back to the dd.mm.YYYY (format)
    date = dateISOtoView(date.toISOString());

    return date;
};


/*
    Calculates the list of days between a start and an end.
        listOfDays('22.12.2018', '24.12.2018') => ['22.12.2018', '23.12.2018', '24.12.2018']
*/
export const listOfDays = function (startDay, endDay) {
    let start    = moment(fullYear(startDay), 'DD.MM.YYYY'),
        end      = moment(fullYear(endDay),   'DD.MM.YYYY'),
        nrOfDays = end.diff(start, 'days') + 1,
        arrayOfDates = [];

    for (let i = 0; i < nrOfDays; i++) {
        arrayOfDates.push(start.format('DD.MM.YYYY') + ' 00:00:00');
        start.add(1, 'days');
    }

    return arrayOfDates;
};

export const listOfDaysWithoutTime = function (startDay, endDay) {
    let start    = moment(fullYear(startDay), 'DD.MM.YYYY'),
        end      = moment(fullYear(endDay),   'DD.MM.YYYY'),
        nrOfDays = end.diff(start, 'days') + 1,
        arrayOfDates = [];

    for (let i = 0; i < nrOfDays; i++) {
        arrayOfDates.push(start.format('DD.MM.YYYY'));
        start.add(1, 'days');
    }

    return arrayOfDates;
};


export const listOfNights = function (startDay, endDay) {
    return listOfDays(startDay, endDay).slice(0, -1);
};


export const daysToNumbers = function (obj) {
    return [
        ...(obj.monday ?    [0] : []),
        ...(obj.tuesday ?   [1] : []),
        ...(obj.wednesday ? [2] : []),
        ...(obj.thursday ?  [3] : []),
        ...(obj.friday ?    [4] : []),
        ...(obj.saturday ?  [5] : []),
        ...(obj.sunday ?    [6] : []),
    ];
};


export const numbersToDays = function (arr) {
    return {
        monday:     includes(arr, 0),
        tuesday:    includes(arr, 1),
        wednesday:  includes(arr, 2),
        thursday:   includes(arr, 3),
        friday:     includes(arr, 4),
        saturday:   includes(arr, 5),
        sunday:     includes(arr, 6),
    };
};


export const today = function () {
    return dateISOtoView((new Date()).toISOString());
};


export const weekDay = function (date) {
    const number = moment(date, 'DD.MM.YYYY HH:mm:ss').isoWeekday();
    switch (number) {
        case 1:
            return 'monday';
        case 2:
            return 'tuesday';
        case 3:
            return 'wednesday';
        case 4:
            return 'thursday';
        case 5:
            return 'friday';
        case 6:
            return 'saturday';
        case 7:
            return 'sunday';
    }
};


export const validateEmail = function (email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase().trim());
};


/*
    Price View
        Filter - transform price into german format

        '100000'    => '100.000,00'
        '20.34'     => '20,34'
*/
export const priceView = function (price, digits = 2) {
    // TODO - maybe use https://stackoverflow.com/questions/3753483/javascript-thousand-separator-string-format/19840881
    if (price !== undefined && price !== null && price !== '') {
        let sign = '';

        // Adding the comma
        let commaString = parseFloat(price.toString().replace(',', '.')).toFixed(digits).replace('.', ',');

        // Separating the sign (if any) because it interferes with the dot addition below
        if (commaString[0] === '+' || commaString[0] === '-') {
            sign        = commaString[0];
            commaString = commaString.slice(1);
        }


        // 6 because of the decimals
        let final = commaString.slice(-1 * (digits + 4)),
            rest  = commaString.slice(0, -1 * (digits + 4));
        if(digits === 0) {
            final = commaString.slice(-1 * (digits + 3));
            rest  = commaString.slice(0, -1 * (digits + 3));
        }

        // Adding a dot every 3 digits
        while (rest) {
            final = rest.slice(-3) + '.' + final;
            rest  = rest.slice(0, -3);
        }

        // We only show the minus sign (default is a positive number)
        return (sign === '-' ? '-' : '') + final;
    } else {
        return '';
    }
};

export const stripHtml = function (value) {
    const div = document.createElement('div');
    div.innerHTML = value;
    return div.textContent || div.innerText || '';
};


/*
    Gets the value of the key from an option list
        with key `new` get from [{key: 'new',   value: 'New'},    the value `New`
                                 {key: 'old',   value: 'Old'},
                                 {key: 'other', value: 'Other'},]
*/
export const getValueWithKey = function ({ key, optionList }) {
    if (optionList) {
        let item = optionList.find(x => JSON.stringify(x.key) === JSON.stringify(key));
        return item ? item.value : key;
    } else {
        return key;
    }
};

export const getAccommodationLabel = function (type, persons, options) {
    return options
        .find(roomType => roomType.type === type && roomType.persons === persons).name;
};

/*
    Search function by letters (not simple substring)
*/
export const getFilePath = function (id) {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return '/api/documents/download/' + id + '?bearer=' + user.token;
    } else {
        return 'user token missing!';
    }
};
export const downloadDocument = function(document) {
    if(document.cloudStorage){
        return document.path;
    }
    return getFilePath(document.id)

};

export const getPreviewFilePath = function (document) {
    return encodeURIComponent(window.location.protocol + '//' + window.location.host + getFilePath(document.id));
};


/*
    Search function by letters (not simple substring)
*/
export const searchByLetters = function (search, stringValue) {
    let searchValue  = search.toLowerCase(),
        restOfString = stringValue.toLowerCase(),
        index = -1;

    for (let i = 0, len = searchValue.length; i < len; i++) {
        index = restOfString.indexOf(searchValue[i]);

        if (index !== -1) {
            restOfString = restOfString.slice(index + 1);
        } else {
            return false;
        }
    }

    return true;
};


/*
    Clears empty items in array; for addresses and contacts
*/
export const cleanEmptyItemsInArray = function (item) {
    let clean = function (array, emptyItem) {
        for (let i = 0, len = array.length; i < len;) {
            if (JSON.stringify(array[i]) === JSON.stringify(emptyItem)) {
                array.splice(i, 1);
            } else {
                i++;
            }
        }
    };

    if (item.addresses) {
        clean(item.addresses, emptyAddress);
    }

    if (item.contacts) {
        clean(item.contacts, emptyContact);
    }

    if (item.otherServices) {
        clean(item.otherServices, emptyOtherService);
    }
};


export const countryName = function (label) {
    const foundCountry = countries.find(country => country.label === label);
    if (foundCountry) {
        return foundCountry.name;
    }
    return label;
};


export const googleMapsLink = function (item) {
    let suffix = '';

    if (item.addresses && item.addresses.length > 0) {
        let address = item.addresses.find(a => a.type === 'primary');

        if (!address) {
            address = item.addresses.find(a => a.type === 'delivery');
        }

        if (!address) {
            address = item.addresses.find(a => a.type === 'billing');
        }

        if (!address) {
            address = item.addresses.find(a => a.type === 'billing_delivery');
        }

        if (address) {
            suffix = `${address.streetAddress}, ${address.zip}, ${address.city}, ${countryName(address.country)}`;
        }
    } else if (item.place) {
        if (item.place.name && item.place.address) {
            suffix = `${item.place.name}, ${item.place.address}`;
        } else if (item.place.name) {
            suffix = item.place.name;
        }
    } else if (item.destination) {
        if (item.destination.name && item.destination.address) {
            suffix = `${item.destination.name}, ${item.destination.address}`;
        } else if (item.destination.name) {
            suffix = item.destination.name;
        }
    } else if (has(item, 'streetAddress')) {
        suffix = `${item.streetAddress}, ${item.zip}, ${item.city}, ${countryName(item.country)}`;
    }

    return !!suffix && `https://www.google.de/maps/search/${suffix}`;
};


/*
    Preparation for backend

        - Makes a copy of the object
        - transforms countries from {label: 'RO', name: 'Romania'} into 'Romania'
        - transforms destinations from  [{id: 1, name: 'Corsica', ...}, ..] into ['/api/destinations/1', ...]

        * add empty address / contact when you want to clear empty objects
*/
export const prepareForBackend = function (item, crudRows = null) {
    // Creating a copy
    let itemCopy = JSON.parse(JSON.stringify(item));
    if (itemCopy.organisationFilter && typeof itemCopy.organisationFilter === 'object') {
        itemCopy.organisationFilter = '/api/organisations/' + itemCopy.organisationFilter.id
    }
    if (itemCopy.startAt) {
        itemCopy.startAt = moment(itemCopy.startAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm:ss');
    }
    if (itemCopy.endAt) {
        itemCopy.endAt = moment(itemCopy.endAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm:ss');
    }
    if (itemCopy.serviceInvoice && itemCopy.serviceInvoice.id) {
        itemCopy.serviceInvoice = '/api/invoices/' + itemCopy.serviceInvoice.id;
    }
    if (itemCopy.liquidityItem && itemCopy.liquidityItem.bankAccount && itemCopy.liquidityItem.bankAccount.id) {
        itemCopy.liquidityItem.bankAccount = '/api/organisation_bank_accounts/' + itemCopy.liquidityItem.bankAccount.id;
    }
    if (itemCopy.accountPlanItem && itemCopy.accountPlanItem.id) {
        itemCopy.accountPlanItem = '/api/account_plan_items/' + itemCopy.accountPlanItem.id;
    }
    if (itemCopy.taxPercentage || itemCopy.taxPercentage === 0) {
        itemCopy.taxPercentage = itemCopy.taxPercentage.toString();
    }
    if (itemCopy.otherServices) {
        itemCopy.otherServices = itemCopy.otherServices
            .map(service => {
                let otherService = {
                    ...service.provider ? {
                        provider: typeof service.provider === 'object'
                            ? `/api/providers/${service.provider.id}`
                            : service.provider
                    } : { provider : null },
                    ...service.agency ? {
                        agency: typeof service.agency === 'object'
                            ? `/api/agencies/${service.agency.id}`
                            : service.agency
                    } : { agency : null },
                    ...!!service.place && {
                        place: typeof service.place === 'object'
                            ? `/api/places/${service.place.id}`
                            : service.place
                    },
                    ...!!service.area && {
                        area: typeof service.area === 'object'
                            ? `/api/areas/${service.area.id}`
                            : service.area
                    },
                    ...!!service.destination && {
                        destination: typeof service.destination === 'object'
                            ? `/api/destinations/${service.destination.id}`
                            : service.destination
                    },

                    ...!!service.otherServiceType && {
                        otherServiceType: `/api/other_service_types/${service.otherServiceType.id}`,
                        priceType: service.otherServiceType.priceType,
                        serviceDescription: service.serviceDescription ? service.serviceDescription : service.otherServiceType.serviceDescription,
                        routeDescription: service.otherServiceType.routeDescription,
                        ...!!service.otherServiceType.area && {
                            area: typeof service.otherServiceType.area === 'object'
                                ? `/api/areas/${service.otherServiceType.area.id}`
                                : service.otherServiceType.area
                        },
                        ...!!service.otherServiceType.place && {
                            place: typeof service.otherServiceType.place === 'object'
                                ? `/api/places/${service.otherServiceType.place.id}`
                                : service.otherServiceType.place
                        },
                        ...!!service.otherServiceType.destination && {
                            destination: typeof service.otherServiceType.destination === 'object'
                                ? `/api/destinations/${service.otherServiceType.destination.id}`
                                : service.otherServiceType.destination
                        }
                    },
                    description: service.description,
                    subtitle: service.subtitle,
                    ...service.prices && {
                        prices: service.prices.map(price => Object.assign({}, price, {
                            startAt:            price.startAt ? moment(price.startAt, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm:ss') : null,
                            endAt:              price.endAt ? moment(price.endAt, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm:ss') : null,

                            ...!!price.destination && {
                                destination: price.destination.id ? `/api/destinations/${price.destination.id}` : price.destination
                            },
                            ...!!price.area && {
                                area: price.area.id ? `/api/areas/${price.area.id}` : price.area
                            },
                            ...!!price.place && {
                                place: price.place.id ? `/api/places/${price.place.id}` : price.place
                            },

                            agency:             price.agency            ? `/api/agencies/${price.agency.id}` : null,
                            otherServiceType:   price.otherServiceType  ? `/api/other_service_types/${price.otherServiceType.id}` : null
                        }))
                    }
                };

                if (service.id) {
                    otherService.id = service.id;
                }

                return otherService;
            });
    }

    // Infos
    if (itemCopy.infos) {
        itemCopy.infos = itemCopy.infos.map(info => (typeof info === 'object' ? ({
            ...info,
            ...info.images && {
                images: info.images.map(image => `/api/images/${image.id}`)
            },
            ...!!info.tripDetailType && {
                providerTripDetail: {
                    title: info.title,
                    text: info.content,
                    type: info.tripDetailType,
                    isOnlyEditableInOrder: false,
                }
            },
            ...info.provider && {
                provider: typeof info.provider === 'object'
                    ? `/api/providers/${info.provider.id}`
                    : info.provider
            }
        }) : info));
    }
    if (itemCopy.clientVisits) {
        itemCopy.clientVisits.map(item => {
            if (item.user && typeof (item.user) === 'object') {
                item.user = '/api/users/' + item.user.id; }
        });
    }

    if (itemCopy.predefinedRouteDetails) {
        itemCopy.predefinedRouteDetails = itemCopy.predefinedRouteDetails
            .map(route => `/api/predefined_route_details/${route.id}`);
    }

    if (itemCopy.users) {
        itemCopy.users = itemCopy.users
            .map(route => `/api/users/${itemCopy.users.id}`);
    }

    if (itemCopy.address && itemCopy.address.id) {
        itemCopy.address = '/api/addresses/' + itemCopy.address.id;
    }

    if (itemCopy.bank && itemCopy.bank.id) {
        itemCopy.bank = '/api/banks/' + itemCopy.bank.id;
    }

    /*if (itemCopy.checkbooks && itemCopy.checkbooks[0].id) {
        itemCopy.checkbooks = ['/api/check_books/' + itemCopy.checkbooks[0].id];
    }*/

    if (itemCopy.port && itemCopy.port.id) {
        itemCopy.port = '/api/ports/' + itemCopy.port.id;
    }

    // Agency Singular
    if (itemCopy.agency && itemCopy.agency.id) {
        itemCopy.agency = '/api/agencies/' + itemCopy.agency.id;
    }

    // Agencies Plural
    if (itemCopy.agencies) {
        itemCopy.agencies = itemCopy.agencies
            .map(agency => `/api/agencies/${agency.id}`);
    }

    // Area Singular
    if (itemCopy.area && itemCopy.area.id) {
        itemCopy.area = '/api/areas/' + itemCopy.area.id;
    }

    // Areas Plural
    if (itemCopy.areas) {
        itemCopy.areas = itemCopy.areas
            .map(area => `/api/areas/${area.id}`);
    }

    // Days Plural
    if (itemCopy.days) {
        itemCopy.days = itemCopy.days
            .map(day => `/api/day_concepts/${day.id}`);
    }

    // Hotel Singular
    if (itemCopy.hotel && itemCopy.hotel.id) {
        itemCopy.hotel = '/api/hotels/' + itemCopy.hotel.id;
    }


    // Ferry Singular
    if (itemCopy.ferry && itemCopy.ferry.id) {
        itemCopy.ferry = '/api/ferries/' + itemCopy.ferry.id;
    }


    // StartPort
    if (itemCopy.startPort && itemCopy.startPort.id) {
        itemCopy.startPort = '/api/ports/' + itemCopy.startPort.id;
    }

    // EndPort
    if (itemCopy.endPort && itemCopy.endPort.id) {
        itemCopy.endPort = '/api/ports/' + itemCopy.endPort.id;
    }


    // Train Singular
    if (itemCopy.train && itemCopy.train.id) {
        itemCopy.train = '/api/trains/' + itemCopy.train.id;
    }

    // StartTrainStation
    if (itemCopy.startTrainStation && itemCopy.startTrainStation.id) {
        itemCopy.startTrainStation = '/api/train_stations/' + itemCopy.startTrainStation.id;
    }

    // EndTrainStation
    if (itemCopy.endTrainStation && itemCopy.endTrainStation.id) {
        itemCopy.endTrainStation = '/api/train_stations/' + itemCopy.endTrainStation.id;
    }

    // Properties Plural
    if (itemCopy.properties) {
        itemCopy.properties = itemCopy.properties
            .map(property => {
                if (property.providerLink) {
                    return Object.assign({}, property, {
                        providerLink: `/api/providers/${property.providerLink.id}`
                    });
                }

                return property;
            });
    }


    // StartAirport
    if (itemCopy.startAirport && itemCopy.startAirport.id) {
        itemCopy.startAirport = '/api/airports/' + itemCopy.startAirport.id;
    }

    // EndAirport
    if (itemCopy.endAirport && itemCopy.endAirport.id) {
        itemCopy.endAirport = '/api/airports/' + itemCopy.endAirport.id;
    }


    // Destination Singular
    if (itemCopy.destination) {
        itemCopy.destination = '/api/destinations/' + itemCopy.destination.id;
    }

    // Destinations Plural
    if (itemCopy.destinations) {
        itemCopy.destinations = itemCopy.destinations
            .map(destination => `/api/destinations/${destination.id}`);
    }

    if (itemCopy.locationCharges) {
        itemCopy.locationCharges = itemCopy.locationCharges.map(locationCharge => `/api/location_charges/${locationCharge.id}`);
    }

    if (itemCopy.sellingPrices) {
        for (let i = 0, len = itemCopy.sellingPrices.length; i < len; i++) {
            itemCopy.sellingPrices[i].pax = Number(itemCopy.sellingPrices[i].pax);

            if (itemCopy.sellingPrices[i].dateRange) {
                const range = itemCopy.sellingPrices[i].dateRange;
                const dates = dateRange(range);
                delete itemCopy.sellingPrices[i].dateRange;

                itemCopy.sellingPrices[i].startAt = dates[0] + ' 00:00:00';
                itemCopy.sellingPrices[i].endAt = dates[1] + ' 00:00:00';
            }
        }
    }

    if (itemCopy.itemPrices) {
        for (let i = 0, len = itemCopy.itemPrices.length; i < len; i++) {
            if (typeof itemCopy.itemPrices[i] === 'object') {
                itemCopy.itemPrices[i].pax = Number(itemCopy.itemPrices[i].pax);
            }

            if (itemCopy.itemPrices[i].startAt) {
                itemCopy.itemPrices[i].startAt = moment(itemCopy.itemPrices[i].startAt, 'DD.MM.YYYY')
                    .format('DD.MM.YYYY HH:mm:ss');
            }

            if (itemCopy.itemPrices[i].endAt) {
                itemCopy.itemPrices[i].endAt = moment(itemCopy.itemPrices[i].endAt, 'DD.MM.YYYY')
                    .format('DD.MM.YYYY HH:mm:ss');
            }

            if (itemCopy.itemPrices[i].minPersons === '') {
                itemCopy.itemPrices[i].minPersons = null;
            }

            if (itemCopy.itemPrices[i].maxPersons === '') {
                itemCopy.itemPrices[i].maxPersons = null;
            }
        }
    }

    if (itemCopy.images) {
        itemCopy.images = itemCopy.images
            .map(image => `/api/images/${image.id}`);
    }

    if (itemCopy.providerTripDetails) {
        itemCopy.providerTripDetails = itemCopy.providerTripDetails.map(tripDetail => {
            if (typeof tripDetail === 'string') {
                return tripDetail;
            }
            return {
                ...tripDetail,
                ...tripDetail.images && {
                    images: tripDetail.images.map(image => `/api/images/${image.id}`)
                },
                ...tripDetail.info && {
                    info: typeof tripDetail.info === 'string' ? tripDetail.info : `/api/infos/${tripDetail.info.id}`
                },
                ...tripDetail.provider && {
                    provider: typeof tripDetail.provider === 'string'
                        ? tripDetail.provider
                        : `/api/providers/${tripDetail.provider.id}`
                },
                ...!!tripDetail.image && { image: `/api/images/${tripDetail.image.id}` }
            }
        });
    }

    if (itemCopy.headerImage) {
        if (itemCopy.headerImage.id) {
            itemCopy.headerImage = `/api/images/${itemCopy.headerImage.id}`;
        } else {
            delete itemCopy.headerImage;
        }
    }

    // Place Singular
    if (itemCopy.place) {
        itemCopy.place = '/api/places/' + itemCopy.place.id;
    }

    if (itemCopy.startPlace) {
        itemCopy.startPlace = '/api/places/' + itemCopy.startPlace.id;
    }

    if (itemCopy.endPlace) {
        itemCopy.endPlace = '/api/places/' + itemCopy.endPlace.id;
    }

    // Places Plural
    if (itemCopy.places) {
        itemCopy.places = itemCopy.places
            .map(place => `/api/places/${place.id}`);
    }


    // Ferry Routes
    if (itemCopy.ferryRoutes) {
        for (let i = 0, len = itemCopy.ferryRoutes.length; i < len; i++) {
            if (itemCopy.ferryRoutes[i].startPort) {
                itemCopy.ferryRoutes[i].startPort = itemCopy.ferryRoutes[i].startPort
                    ? '/api/ports/' + itemCopy.ferryRoutes[i].startPort.id
                    : null;
            }

            if (itemCopy.ferryRoutes[i].startPort) {
                itemCopy.ferryRoutes[i].endPort   = itemCopy.ferryRoutes[i].endPort
                    ? '/api/ports/' + itemCopy.ferryRoutes[i].endPort.id
                    : null;
            }

            itemCopy.ferryRoutes[i].prices = itemCopy.ferryRoutes[i].prices ? itemCopy.ferryRoutes[i].prices.map(price => ({
                ...price,
                startAt: moment(price.startAt, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm:ss'),
                endAt: moment(price.endAt, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm:ss'),
                agency: price.agency ? `/api/agencies/${price.agency.id}` : null,
            })) : [];


            if (itemCopy.ferryRoutes[i].return) {
                itemCopy.ferryRoutes.push({
                    ferry: itemCopy.ferryRoutes[i].ferry,
                    startPort: itemCopy.ferryRoutes[i].endPort,
                    endPort: itemCopy.ferryRoutes[i].startPort,
                    type: itemCopy.ferryRoutes[i].type,
                });
            }
        }
    }
    // Train Routes
    if (itemCopy.trainRoutes) {
        for (let i = 0, len = itemCopy.trainRoutes.length; i < len; i++) {
            if (itemCopy.trainRoutes[i].startTrainStation) {
                itemCopy.trainRoutes[i].startTrainStation = itemCopy.trainRoutes[i].startTrainStation
                    ? '/api/train_stations/' + itemCopy.trainRoutes[i].startTrainStation.id
                    : null;
            }
            if (itemCopy.trainRoutes[i].startTrainStation) {
                itemCopy.trainRoutes[i].endTrainStation   = itemCopy.trainRoutes[i].endTrainStation
                    ? '/api/train_stations/' + itemCopy.trainRoutes[i].endTrainStation.id
                    : null;
            }

            itemCopy.trainRoutes[i].prices = itemCopy.trainRoutes[i].prices ? itemCopy.trainRoutes[i].prices.map(price => ({
                ...price,
                startAt: moment(price.startAt, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm:ss'),
                endAt: moment(price.endAt, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm:ss'),
                agency: price.agency ? `/api/agencies/${price.agency.id}` : null,
            })) : [];


            if (itemCopy.trainRoutes[i].return) {
                itemCopy.trainRoutes.push({
                    train: itemCopy.trainRoutes[i].train,
                    startTrainStation: itemCopy.trainRoutes[i].endTrainStation,
                    endTrainStation: itemCopy.trainRoutes[i].startTrainStation,
                    type: itemCopy.trainRoutes[i].type,
                });
            }
        }
    }


    if (itemCopy.prices) {
        for (let i = 0, len = itemCopy.prices.length; i < len; i++) {
            if (itemCopy.prices[i].agency) {
                if (itemCopy.prices[i].agency.id) {
                    itemCopy.prices[i].agency = '/api/agencies/' + itemCopy.prices[i].agency.id;
                } else {
                    itemCopy.prices[i].agency = null;
                }
            }
            if (itemCopy.prices[i].area && typeof itemCopy.prices[i].area === 'object') {
                itemCopy.prices[i].area = '/api/areas/' + itemCopy.prices[i].area.id;
            }
            if (itemCopy.prices[i].destination) {
                itemCopy.prices[i].destination = '/api/destinations/' + itemCopy.prices[i].destination.id;
            }
            if (itemCopy.prices[i].place) {
                itemCopy.prices[i].place = '/api/places/' + itemCopy.prices[i].place.id;
            }

            if (itemCopy.prices[i].startAt) {
                itemCopy.prices[i].startAt =  moment(itemCopy.prices[i].startAt, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm:ss');
            }
            if (itemCopy.prices[i].endAt) {
                itemCopy.prices[i].endAt =  moment(itemCopy.prices[i].endAt, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm:ss');
            }
        }
    }


    // Airline Singular
    if (itemCopy.airline && itemCopy.airline.id) {
        itemCopy.airline = '/api/airlines/' + itemCopy.airline.id;
    }

    // Airline Routes
    if (itemCopy.airlineRoutes) {
        for (let i = 0, len = itemCopy.airlineRoutes.length; i < len; i++) {
            itemCopy.airlineRoutes[i].startAirport = `api/airports/${itemCopy.airlineRoutes[i].startAirport.id}`;
            itemCopy.airlineRoutes[i].endAirport = `api/airports/${itemCopy.airlineRoutes[i].endAirport.id}`;

            if (itemCopy.airlineRoutes[i].return) {
                itemCopy.airlineRoutes.push({
                    startAirport: itemCopy.airlineRoutes[i].endAirport,
                    endAirport: itemCopy.airlineRoutes[i].startAirport,
                });
            }
        }
    }

    if (itemCopy.contacts) {
        for (let i = 0, len = itemCopy.contacts.length; i < len; i++) {
            if (itemCopy.contacts[i].place) {
                itemCopy.contacts[i].place = `api/places/${itemCopy.contacts[i].place.id}`;
            }
            if (itemCopy.contacts[i].address) {
                itemCopy.contacts[i].address = `api/addresses/${itemCopy.contacts[i].address.id}`;
            }
            if (has(itemCopy.contacts[i], 'provider') && itemCopy.contacts[i].provider === null) {
                delete itemCopy.contacts[i].provider;
            }
            if (has(itemCopy.contacts[i], 'agency') && itemCopy.contacts[i].agency === null) {
                delete itemCopy.contacts[i].agency;
            }
            if (has(itemCopy.contacts[i], 'client') && itemCopy.contacts[i].client === null) {
                delete itemCopy.contacts[i].client;
            }
        }
    }

    if (itemCopy.addresses) {
        for (let i = 0, len = itemCopy.addresses.length; i < len; i++) {
            if (itemCopy.addresses[i].port) {
                itemCopy.addresses[i].port = `api/ports/${itemCopy.addresses[i].port.id}`;
            }
            if (itemCopy.addresses[i].airport) {
                itemCopy.addresses[i].airport = `api/airports/${itemCopy.addresses[i].airport.id}`;
            }

            if (has(itemCopy.addresses[i], 'id')) {
                delete itemCopy.addresses[i].contacts;
            }
        }
    }
    if (itemCopy.organisationBankAccounts) {
        itemCopy.organisationBankAccounts = itemCopy.organisationBankAccounts.map(account => (typeof account === 'object' ? {
            ...account,
            ...account.accountPlanItem && account.accountPlanItem.id && {
                accountPlanItem: `/api/account_plan_items/${account.accountPlanItem.id}`
            },
            ...account.bank && account.bank.id && {
                bank: `/api/banks/${account.bank.id}`
            },
            ...account.mainOrganisationBankAccount && account.mainOrganisationBankAccount.id && {
                mainOrganisationBankAccount: `/api/organisation_bank_accounts/${account.mainOrganisationBankAccount.id}`,
            }
        } : account));
    }

    // Placeholders
    // TODO - REMOVE IT (check first if it is still used)
    if (itemCopy.location) {
        if (itemCopy.location.name === 'direkt' || itemCopy.location.name === 'Kein Ort' || itemCopy.location.name === 'Ortsangabe') {
            itemCopy.place       = null;
            itemCopy.area        = null;
            itemCopy.destination = null;
        } else if (itemCopy.location.area !== undefined && itemCopy.location.destination !== undefined) {
            itemCopy.place   = '/api/places/' + itemCopy.location.id;
            itemCopy.area    = null;
            itemCopy.destination = null;
        } else if (itemCopy.location.destination !== undefined) {
            itemCopy.place   = null;
            itemCopy.area    = '/api/areas/' + itemCopy.location.id;
            itemCopy.destination = null;
        } else {
            itemCopy.place   = null;
            itemCopy.area    = null;
            itemCopy.destination = '/api/destinations/' + itemCopy.location.id;
        }

        delete itemCopy.location;
    }


    if (itemCopy.hotelPlaceholders) {
        for (let i = 0, len = itemCopy.hotelPlaceholders.length; i < len; i++) {
            // if (itemCopy.hotelPlaceholders[i].id) {
            //     itemCopy.hotelPlaceholders[i] = `/api/hotel_placeholders/${itemCopy.hotelPlaceholders[i].id}`;

            // } else {
            if (itemCopy.hotelPlaceholders[i].location) {
                if (has(itemCopy.hotelPlaceholders[i].location, 'url') || has(itemCopy.hotelPlaceholders[i].location, 'addresses') || has(itemCopy.hotelPlaceholders[i].location, 'contacts')) {
                    // It is a place
                    itemCopy.hotelPlaceholders[i].place = '/api/places/' + itemCopy.hotelPlaceholders[i].location.id;
                    itemCopy.hotelPlaceholders[i].area   = null;
                } else {
                    // It is an area
                    itemCopy.hotelPlaceholders[i].place = null;
                    itemCopy.hotelPlaceholders[i].area  = '/api/areas/' + itemCopy.hotelPlaceholders[i].location.id;
                }
                delete itemCopy.hotelPlaceholders[i].location;
            }
            if (itemCopy.hotelPlaceholders[i].area) {
                itemCopy.hotelPlaceholders[i].area = '/api/areas/' + itemCopy.hotelPlaceholders[i].area.id;
            }
            if (itemCopy.hotelPlaceholders[i].place) {
                itemCopy.hotelPlaceholders[i].place = '/api/places/' + itemCopy.hotelPlaceholders[i].place.id;
            }
            if (itemCopy.hotelPlaceholders[i].destination) {
                itemCopy.hotelPlaceholders[i].destination = '/api/destinations/' + itemCopy.hotelPlaceholders[i].destination.id;
            }
            if (itemCopy.hotelPlaceholders[i].agency) {
                itemCopy.hotelPlaceholders[i].agency = '/api/agencies/' + itemCopy.hotelPlaceholders[i].agency.id;
            }
            if (itemCopy.hotelPlaceholders[i].isBrandNew) {
                delete itemCopy.hotelPlaceholders[i].isBrandNew;
            }
            // }
        }
    }

    if (itemCopy.ferryPlaceholders) {
        for (let i = 0, len = itemCopy.ferryPlaceholders.length; i < len; i++) {
            // if (itemCopy.ferryPlaceholders[i].id) {
            //     itemCopy.ferryPlaceholders[i] = `/api/ferry_placeholders/${itemCopy.ferryPlaceholders[i].id}`;

            // } else {
            if (itemCopy.ferryPlaceholders[i].startPort) {
                itemCopy.ferryPlaceholders[i].startPort = '/api/ports/' + itemCopy.ferryPlaceholders[i].startPort.id;
            }
            if (itemCopy.ferryPlaceholders[i].endPort) {
                itemCopy.ferryPlaceholders[i].endPort = '/api/ports/' + itemCopy.ferryPlaceholders[i].endPort.id;
            }
            if (itemCopy.ferryPlaceholders[i].agency) {
                itemCopy.ferryPlaceholders[i].agency = '/api/agencies/' + itemCopy.ferryPlaceholders[i].agency.id;
            }
            if (itemCopy.ferryPlaceholders[i].isBrandNew) {
                delete itemCopy.ferryPlaceholders[i].isBrandNew;
            }
            // }
        }
    }
    if (itemCopy.trainPlaceholders) {
        for (let i = 0, len = itemCopy.trainPlaceholders.length; i < len; i++) {
            if (itemCopy.trainPlaceholders[i].startTrainStation && itemCopy.trainPlaceholders[i].startTrainStation.id) {
                itemCopy.trainPlaceholders[i].startTrainStation = '/api/train_stations/' + itemCopy.trainPlaceholders[i].startTrainStation.id;
            }
            if (itemCopy.trainPlaceholders[i].endTrainStation && itemCopy.trainPlaceholders[i].endTrainStation.id) {
                itemCopy.trainPlaceholders[i].endTrainStation = '/api/train_stations/' + itemCopy.trainPlaceholders[i].endTrainStation.id;
            }
            if (itemCopy.trainPlaceholders[i].agency) {
                itemCopy.trainPlaceholders[i].agency = '/api/agencies/' + itemCopy.trainPlaceholders[i].agency.id;
            }
            if (itemCopy.trainPlaceholders[i].isBrandNew) {
                delete itemCopy.trainPlaceholders[i].isBrandNew;
            }
            // }
        }
    }


    if (itemCopy.otherPlaceholders) {
        for (let i = 0, len = itemCopy.otherPlaceholders.length; i < len; i++) {
            if (itemCopy.otherPlaceholders[i].otherServiceType) {
                itemCopy.otherPlaceholders[i].otherServiceType = `/api/other_service_types/${itemCopy.otherPlaceholders[i].otherServiceType.id}`;
            }

            if (itemCopy.otherPlaceholders[i].area) {
                itemCopy.otherPlaceholders[i].area = '/api/areas/' + itemCopy.otherPlaceholders[i].area.id;
            }
            if (itemCopy.otherPlaceholders[i].place) {
                itemCopy.otherPlaceholders[i].place = '/api/places/' + itemCopy.otherPlaceholders[i].place.id;
            }
            if (itemCopy.otherPlaceholders[i].destination) {
                itemCopy.otherPlaceholders[i].destination = '/api/destinations/' + itemCopy.otherPlaceholders[i].destination.id;
            }
        }
    }


    if (itemCopy.airlinePlaceholders) {
        for (let i = 0, len = itemCopy.airlinePlaceholders.length; i < len; i++) {
            if (itemCopy.airlinePlaceholders[i].startAirport) {
                itemCopy.airlinePlaceholders[i].startAirport = `/api/airports/${itemCopy.airlinePlaceholders[i].startAirport.id}`;
            }

            if (itemCopy.airlinePlaceholders[i].endAirport) {
                itemCopy.airlinePlaceholders[i].endAirport = `/api/airports/${itemCopy.airlinePlaceholders[i].endAirport.id}`;
            }
        }
    }

    if (itemCopy.routeDetails) {
        for (let i = 0, len = itemCopy.routeDetails.length; i < len; i++) {
            if (itemCopy.routeDetails[i].predefinedRouteDetail) {
                itemCopy.routeDetails[i].predefinedRouteDetail = `/api/predefined_route_details/${itemCopy.routeDetails[i].predefinedRouteDetail.id}`;
            }
        }
    }


    // Route Detail
    if (itemCopy.predefinedRouteDetail && itemCopy.predefinedRouteDetail.id) {
        itemCopy.predefinedRouteDetail = '/api/predefined_route_details/' + itemCopy.predefinedRouteDetail.id;
    }
    if (itemCopy.pricePerPerson && itemCopy.pricePerPerson.amount) {
        itemCopy.pricePerPerson.amount = itemCopy.pricePerPerson.amount.toString();
    }


    // Other Placeholder
    if (itemCopy.otherService && itemCopy.otherService.id && !itemCopy.otherService.useNestedData) {
        itemCopy.otherService = '/api/other_services/' + itemCopy.otherService.id;
    }

    if (itemCopy.mainUser) {
        itemCopy.mainUser = '/api/users/' + itemCopy.mainUser.id;
    }

    if (itemCopy.mainUsers) {
        itemCopy.mainUsers = itemCopy.mainUsers.map(user => '/api/users/' + user.id);
    }

    if (itemCopy.offerProvider) {
        itemCopy.offerProvider = `/api/providers/${itemCopy.offerProvider.id}`;
    }


    // isBrandNew
    if (itemCopy.isBrandNew) {
        delete itemCopy.isBrandNew;
    }
    // in Trip dashboard when creating a placeholder and a request at the same time
    if (itemCopy.withRequest) {
        delete itemCopy.withRequest;
    }

    if (has(itemCopy, 'numberOfRooms') && itemCopy.numberOfRooms === '') {
        itemCopy.numberOfRooms = null;
    }

    if (has(itemCopy, 'googleMap') && itemCopy.googleMap && itemCopy.googleMap.latitude) {
        const { googleMap } = itemCopy;

        itemCopy.googleMap = {
            latitude: googleMap.latitude.toString(),
            longitude: googleMap.longitude.toString(),
            latitude2: googleMap.latitude2 ? googleMap.latitude2.toString() : null,
            longitude2: googleMap.longitude2 ? googleMap.longitude2.toString() : null,
            centerLatitude: googleMap.centerLatitude.toString(),
            centerLongitude: googleMap.centerLongitude.toString(),
            zoom: googleMap.zoom ? toNumber(googleMap.zoom) : null,
            mode: googleMap.mode
        };
    }

    if (itemCopy.payments) {
        itemCopy.payments.map(item => {
            if (item.liquidityItem && item.liquidityItem.bankAccount && item.liquidityItem.bankAccount.id) {
                item.liquidityItem.bankAccount = '/api/organisation_bank_accounts/' + item.liquidityItem.bankAccount.id;
            }
            return item;
        });
    }

    if(crudRows) {
        crudRows.forEach(row => row.forEach(col => {
            if(col.component === 'Multiselect' && col.props['track-by']) {
                if(Array.isArray(item[col.value])) {
                    itemCopy[col.value] = itemCopy[col.value].map(el => el[col.props['track-by']]);
                }
                else if (typeof item[col.value] === 'object') {
                    itemCopy[col.value] = item[col.value][col.props['track-by']];
                }
            }
        }))
    }


    return itemCopy;
};


export const capitalize = function (value) {
    if (!value) {
        return '';
    }
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
};


export const camelCase = function (value) {
    const parts = value.split('_');
    return parts.map(part => capitalize(part)).join('');
};

export function camelToSnakeCase(str) {
    return str.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export const phone = function (value) {
    console.log(value)
    return value.replace(/[^+\d]+/g, '');
};

export const keyToComponent = function (key) {
    const component = key.split('.').pop().split('_').join('');
    return capitalize(component);
};


export const progressPromise = function (promises, tickCallback) {
    let len = promises.length;
    let progress = 0;

    function tick(promise)
    {
        promise.then(() => {
            progress++;
            tickCallback(progress, len);
        });
        return promise;
    }

    return Promise.all(promises.map(tick));
};


export const copyText = function (text) {
    const textArea = document.createElement('textarea');

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;
    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';

    // Adding the text
    textArea.value = text;
    // Adding the element to the DOM
    document.body.appendChild(textArea);
    // Selecting the text
    textArea.select();
    // Copying the text to clipboard
    document.execCommand('copy');
    // Removing the element
    document.body.removeChild(textArea);
};


export const profit = function (_sellingPrice, _buyingPrice) {
    const sellingPrice = Number(_sellingPrice);
    const buyingPrice = Number(_buyingPrice);


    return (sellingPrice - buyingPrice).toFixed(2);

};


export const margin = function (_sellingPrice, _buyingPrice) {
    const sellingPrice = Number(_sellingPrice);
    const buyingPrice = Number(_buyingPrice);

    const marginValue = 100 * (profit(sellingPrice, buyingPrice) / buyingPrice);

    if (!Number(marginValue)) {
        const num = 0;
        return num.toFixed(2);
    }

    if (!sellingPrice) {
        return null;
    }

    return marginValue.toFixed(2);
};

export const applyMargin = function (_price, _margin) {
    const price = Number(_price);
    const marginValue = Number(_margin);

    if (price && marginValue) {
        return price * (1 + marginValue / 100);
    }

    return price;
};


export const applyProfit = function (_price, _profit) {
    const price = Number(_price);
    const profitValue = Number(_profit);

    return price && profitValue ? price + profitValue : price;
};


export const providerURL = function (provider) {
    const providerTypes = {
        airline: 'airlines',
        hotel:   'hotels',
        ferry:   'ferries',
        train: 'trains',
        other:   'others'
    };
    if (provider) {
        return `/${providerTypes[provider.providerType]}/${provider.id}`;
    }
    return '';
};


export const buildURLQuery = function (arr) {
    return `?${arr.join('&')}`;
};

export const authHeader = function () {
    // return authorization header with jwt token
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return { Authorization: 'Bearer ' + user.token };
    } else {
        return {};
    }
};


export const getStorageFilter = function (filter, key = '', defaultValue = '') {
    if (settings.general.resetFilter) {
        return defaultValue;
    }
    const filtersString = localStorage.getItem('filters');
    const filters = JSON.parse(filtersString);


    if (key === '') {
        if (filters && has(filters, filter)) {
            if (has(filters[filter], 'users') && !filters[filter].users[0]) {
                filters[filter].users = [];
            }
            console.log('getStorageFilters', filters[filter])
            return filters[filter];
        } else {
            return {};
        }
    }

    if (filters && has(filters, filter)) {
        return has(filters[filter], key) ? filters[filter][key] : defaultValue;
    }

    return defaultValue;
};


export const setStorageFilter = function (filter, obj) {


    if (settings.general.resetFilter) {
        return;
    }

    const filtersString = localStorage.getItem('filters');
    const filters = Object.assign({}, JSON.parse(filtersString), {
        [filter] : obj
    });
    console.log('setStorageFilter', obj)

    localStorage.setItem('filters', JSON.stringify(filters));
};

export const getStorageSize = function (key = '') {
    const sizesString = localStorage.getItem('sizes');
    const sizes = JSON.parse(sizesString);

    if (key !== '') {
        return sizes && has(sizes, key) ? sizes[key] : null;
    } else {
        return sizes || {};
    }
};

export const setStorageSize = function (key = '', size) {
    const sizesString = localStorage.getItem('sizes');
    const sizes = {
        ...JSON.parse(sizesString),
        [key] : size
    };

    localStorage.setItem('sizes', JSON.stringify(sizes));
};

export const getStorageTab = function (path) {
    const tabsString = localStorage.getItem('tabs');
    const tabs = JSON.parse(tabsString);

    if (tabs && has(tabs, path)) {
        return tabs[path];
    }

    return '';
};

export const setStorageTab = function (path, tab) {
    const tabsString = localStorage.getItem('tabs');
    const tabs = { ...JSON.parse(tabsString), [path]: tab };
    localStorage.setItem('tabs', JSON.stringify(tabs));
};


export const url = function (urlValue) {
    if (!/^https?:\/\//i.test(urlValue)) {
        return 'http://' + urlValue;
    }

    return urlValue;
};


export const currentUser = function (property = false) {
    const userStore = JSON.parse(localStorage.getItem('user'));

    if (userStore && has(userStore, 'user')) {
        const user = userStore.user;
        if (property) {
            return user[property];
        } else {
            return user;
        }
    }

    return null;
};


export const findGreatest = function (arr, key) {
    return Math.max(...arr.map(obj => toNumber(obj[key])), 0);
};

export const getRandomItem = function (items) {
    return items[Math.floor(Math.random() * (items.length - 1))];
};

export const hexToRgba = function (hex, alpha = 1) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})` : null;
};

export const conceptDays = function (concept) {
    let days = {};

    if (concept) {
        concept.days
            .forEach(day => {
                day.otherPlaceholders.forEach(other => {
                    days = {
                        ...days,
                        ...!!other.durationDays && {
                            [`other[${other.id}][days]`]: other.durationDays
                        }
                    };
                });
            });
    }

    return days;
};

export const conceptNights = function (concept) {
    let nights = {};

    if (concept) {
        concept.days
            .forEach(day => {
                day.hotelPlaceholders.forEach(hotel => {
                    nights = {
                        ...nights,
                        ...!!hotel.nights && {
                            [`hotel[${hotel.id}][nights]`]: hotel.nights
                        }
                    };
                });
            });
    }

    return nights;
};

export const truncate = function (string, length = 15) {
    if (string.length > length) {
        return string.substring(0, length) + '...';
    }

    return string;
};

export const getArrayBuffer = function (file) {
    return new Promise(((resolve, reject) => {
        let reader = new FileReader();

        reader.onloadend = function (e) {
            resolve(e.target.result);
        };
        reader.onerror = function (e) {
            reject(e.target.error);
        };
        reader.readAsArrayBuffer(file);
    }));
};

export const extractEmail = function (string) {
    if (!string) {
        return '';
    }
    let regex = /^"?(.*)"?$/g;
    let matches = regex.exec(string);
    let result = matches[1];
    if (string.includes('<')) {
        regex = /<(.*)>/g; // The actual regex
        matches = regex.exec(string);
        result = matches[1];
    }
    return result.includes('@') ? result : '';
};

export const parseMsgHeaders = function (headers) {
    let parsedHeaders = {};
    let m;

    if (!headers) {
        return parsedHeaders;
    }
    let headerRegEx = /(.*)\: (.*)/g;
    // eslint-disable-next-line no-cond-assign
    while (m = headerRegEx.exec(headers)) {
        // todo: Pay attention! Header can be presented many times (e.g. Received). Handle it, if needed!
        parsedHeaders[m[1]] = m[2];
    }

    return parsedHeaders;
};

export const extractFilename = function (headers) {
    const contentDisposition = headers['content-disposition'];
    const startIndex = contentDisposition.indexOf('filename=') + 10;
    const endIndex = contentDisposition.length - 1;
    const filename = contentDisposition.substring(startIndex, endIndex);
    return filename;
};

export const getPlaceholderType = function (placeholder) {
    return placeholder.info.type;
};

export const getRequestType = function (request) {
    if (request.requestType === 'hotel') {
        return 'hotel';
    } else if (request.requestType === 'ferry') {
        return 'ferry';
    } else if (request.requestType === 'train') {
        return 'train';
    } else if (request.requestType === 'airline') {
        return 'airline';
    } else {
        return 'other';
    }
};

export const getFlag = function (translation) {
    translation = translation === 'de' ? 'de_DE' : translation;
    const foundFlag = getTranslationOptions().find(item => item.code === translation);
    if (foundFlag) {
        return foundFlag.flagCode;
    }
    return translation;
};

export const getAndSetOptionStorage = function (key, api) {
    return new Promise(resolve => {
        let options = getOptionStorageIfNoUpdate(key);
        if (!options) {
            axios.options(`/api/${api}`).then(response => {
                options = response.data[key];
                setOptionStorageAndLastUpdate(key, options);
                resolve(options);
            });
        } else {
            resolve(options);
        }
    });

};

export const getOptionStorageIfNoUpdate = function (key) {
    let lastUpdates = [];
    lastUpdates.organisations = '28.03.2021';
    lastUpdates.classifications = '11.05.2021';
    lastUpdates.routeDetails = '15.02.2022';
    let options = JSON.parse(localStorage.getItem('options'));
    if (options && options[key] && options.lastUpdates &&
        options.lastUpdates[key] && moment(options.lastUpdates[key]) >= moment(lastUpdates[key], 'DD.MM.YYYY')) {
        return options[key];
    }
    return null;
};
export const setOptionStorageAndLastUpdate = function (key, data) {
    let options = localStorage.getItem('options') ? JSON.parse(localStorage.getItem('options')) : {};
    if (!options[key]) {
        options[key] = {};
    }
    if (!options.lastUpdates) {
        options.lastUpdates      = {};
    }

    options.lastUpdates[key] = moment().startOf('day');
    options[key] = data;
    localStorage.setItem('options', JSON.stringify(options));
    return options;
};

export const getOrganisationsFromStorage = function () {
    let options = getOptionStorageIfNoUpdate('organisations');
    if (!options) {
        getOrganisations().then(response => {
            let organisations = response.data;
            setOptionStorageAndLastUpdate('organisations', organisations);
            location.reload();
            return options;
        });
    } else {
        return options;
    }
};

export const getTranslationOptions = function () {
    let options = JSON.parse(localStorage.getItem('options'));


    if (options && options.translations && options.lastUpdates && options.lastUpdates.translations && moment(options.lastUpdates.translations) >= moment('07.09.2020', 'DD.MM.YYYY')) {
        return options.translations;
    } else {
        getLocales().then(response => {
            if (!options) {
                options                  = {};
            }
            if (!options.lastUpdates) {
                options.lastUpdates      = {};
            }

            options.translations = response.data;
            options.lastUpdates.translations = moment().startOf('day');
            localStorage.setItem('options', JSON.stringify(options));
            location.reload();
            return options.translations;
        });
    }
};

export const getIDFromString = function (apiString) {
    if (!apiString) {
        return null;
    }
    if (typeof apiString === 'object') {
        return apiString.id;
    }
    let stringArray = apiString.split('/');
    return toInteger(stringArray[stringArray.length - 1]);
};

export const objectToKeyValueArray = function (items) {
    let array = [];
    for (const item in items) {
        array.push({ key: item, value: items[item] });
    }
    return array;
};

export const exportList = function (url, method, params = {}) {
    return axios({
        url: url, //your url
        method: method,
        responseType: 'blob', // important
        params: params
    })
}

