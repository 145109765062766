var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c("th", [
      _c("label", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label) + ":")]),
    ]),
    _vm._v(" "),
    _c(
      "td",
      [
        _c(
          "multiselect",
          {
            staticClass: "is-shorter",
            attrs: { options: _vm.options, taggable: true, multiple: "" },
            on: {
              tag: (newTag) => {
                _vm.value.push(newTag)
              },
            },
            model: {
              value: _vm.tags,
              callback: function ($$v) {
                _vm.tags = $$v
              },
              expression: "tags",
            },
          },
          [_c("span", { attrs: { slot: "caret" }, slot: "caret" })]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }