<template>
    <crud-table
        ref="table"

        api="orders"
        url="/orders"
        :storeKey="`clients/${item.id}/orders`"

        :filter="{'client.id': item.id}"

        label="Stammdaten"
        :name="{singular: 'reise', plural: 'reisen'}"

        v-on:add="addNewOrder"

        :columns="[
            { key: 'orderNumber', label: '# ReiseNr' },
            'client.companyName',
            'groupName',
            { key: 'startAt', filter: 'startAt[after]' },
            { key: 'endAt', filter: 'startAt[before]' },
            { key: 'trip.destinations', sortable: 'trip.destinations.name' },
            { key: 'user', filter: 'user.id', sortable: 'user.username' },
            { key: 'mostUrgentTodo.dueDate', label: 'Deadline', sortable: 'mostUrgentDueDate' },
            { key: 'currentStatus', filter: 'currentStatus.status', sortable: 'currentStatus.status' },
            { key: 'hotelContingent', sortable: false },
            { key: 'totalClientPayments.formatted', label: 'Umsatz', sortable: false },
        ]"
    >
        <h2 slot="header">REISEÜBERSICHT</h2>
    </crud-table>
</template>

<script>
import { CrudTable } from '@components/CRUD';
import tab from './tab';

export default {
    mixins: [tab],

    components: {
        CrudTable
    },

    methods: {
        addNewOrder: function () {
            window.location.href = '/orders/add';
        },
    }
}
</script>
