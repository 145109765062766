<template>
    <div
        v-if         = "isRouteView"
        class        = "placeholder-view has-requests"
        v-bind:class = "{ 'has-no-route-description' : !placeholder.routeDescription && isRouteView }"
    >
        <div v-bind:title="placeholder.routeDescription">
            <i class="material-icons material-symbols-outlined" v-if="placeholder.type === 'drive'">road</i>
            <i class="material-icons material-symbols-outlined" v-else-if="placeholder.type === 'sport'">directions_bike</i>
            <i class="material-icons material-symbols-outlined" v-else>filter_hdr</i>

            <template v-if="placeholder.routeDescription">
                <template v-if="placeholder.timeOfDay">{{ getValueWithKey({ key: placeholder.timeOfDay, optionList: timeOfDay }) }} </template>

                {{ placeholder.routeDescription }}
                </template>
            <template v-else>ohne Routenbeschreibung</template>

            <div v-bind:class="['route-difficulty', getDifficulty()]" v-tooltip.top="{ content: placeholder.additionalDescription }">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
        </div>


        <div class="is-hidden-on-small"></div>


        <div class="is-60" v-if="!isConcept"></div>
        <div class="is-100 is-hidden-on-small">
            <time-of-day v-if="isConcept" v-bind:start="placeholder.startTime" :end="placeholder.endTime"></time-of-day>
            <time-of-day v-else v-bind:start="placeholder.startAt" :end="placeholder.endAt"></time-of-day>
        </div>

        <div class="contingent-section" v-if="!isConcept"></div>

        <div v-if="!isConcept"></div>

        <div style="justify-content: right; display: flex; align-items: center; flex-shrink: 1"></div>

        <div class="is-130" v-if="!isConcept"></div>



        <div class="action-buttons"><slot></slot></div>

        <div
            v-if="placeholder.sortGroup"
            class="group-icon"
            v-bind:class="'is-' + placeholder.sortGroup"
        ></div>
    </div>
</template>



<script>
import { priceView }       from '@utilities/functions';
import { LocationCharges } from '@components/CRUD/Table/Cells'
import mixins              from './mixins';


export default {
    mixins: [mixins],


    computed: {
        timeOfDay: function () { return this.$store.state.options.timeOfDay; }
    },


    methods: {
        getDifficulty: function () {
            if (this.placeholder.difficulty        === 'simple') { return 'has-1';

            } else if (this.placeholder.difficulty === 'medium') { return 'has-2';

            } else if (this.placeholder.difficulty === 'hard')   { return 'has-3';

            } else { return 'is-hidden'; }
        }
    },


    filters: {
        priceView
    },


    components: {
        LocationCharges
    }
}
</script>
