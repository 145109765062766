var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bp-row-form" },
    [
      _c("div", { staticClass: "is-flex-grow" }, [
        _c("div", { staticClass: "bp-row-form__title" }, [
          _vm._v("Routenstichpunkt"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _vm.options && _vm.options.routeTypes
              ? _c(
                  "toggle-switch",
                  {
                    staticClass: "has-margin-right",
                    model: {
                      value: _vm.copyPlaceholder.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.copyPlaceholder, "type", $$v)
                      },
                      expression: "copyPlaceholder.type",
                    },
                  },
                  _vm._l(_vm.options.routeTypes, function (type) {
                    return _c("option", { domProps: { value: type.key } }, [
                      _c(
                        "i",
                        {
                          staticClass:
                            "material-icons material-symbols-outlined",
                        },
                        [_vm._v(_vm._s(_vm.getIcon(type.key)))]
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn-single-toggle has-margin-right",
                class: [_vm.onlyFromOrder ? "is-active" : ""],
                attrs: { title: "Aktiv = Nur in Reisedestinationen suchen" },
                on: {
                  click: function ($event) {
                    _vm.onlyFromOrder = !_vm.onlyFromOrder
                  },
                },
              },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("flag")])]
            ),
            _vm._v(" "),
            _c(
              "error-wrapper",
              { attrs: { message: _vm.isEmpty } },
              [
                _c(
                  "multiselect",
                  {
                    ref: "route",
                    attrs: {
                      options: _vm.predefinedRoutes,
                      loading: _vm.isLoading,
                      "allow-empty": false,
                      taggable: _vm.isTaggable,
                      "internal-search": false,
                      placeholder: "tippen, um Routenbeschreibung zu finden",
                      label: "routeDescription",
                      "track-by": "routeDescription",
                      "tag-placeholder": "Add this as new tag",
                      "select-label": "",
                    },
                    on: {
                      tag: _vm.addTag,
                      select: _vm.predefinedSelected,
                      "search-change": _vm.getPredefinedRoutesList,
                    },
                    model: {
                      value: _vm.routeModel,
                      callback: function ($$v) {
                        _vm.routeModel = $$v
                      },
                      expression: "routeModel",
                    },
                  },
                  [
                    _c("span", { attrs: { slot: "caret" }, slot: "caret" }, [
                      _c(
                        "a",
                        {
                          staticClass: "multiselect-crud-link",
                          attrs: {
                            href: "/routes?add",
                            title: "füge eine neue Route hinzu",
                            target: "_blank",
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("add"),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "is-160 has-time" }, [
        _c("div", { staticClass: "bp-row-form__title" }, [
          _vm._v("Zeitangaben"),
        ]),
        _vm._v(" "),
        !_vm.isConcept
          ? _c(
              "div",
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    attrs: { title: "Uhrzeit" },
                  },
                  [_vm._v("access_time")]
                ),
                _vm._v(" "),
                _c("date-time", {
                  staticClass: "has-margin-right",
                  model: {
                    value: _vm.copyPlaceholder.startAt,
                    callback: function ($$v) {
                      _vm.$set(_vm.copyPlaceholder, "startAt", $$v)
                    },
                    expression: "copyPlaceholder.startAt",
                  },
                }),
                _vm._v(" -\n                "),
                _c("date-time", {
                  staticClass: "has-margin-left",
                  model: {
                    value: _vm.copyPlaceholder.endAt,
                    callback: function ($$v) {
                      _vm.$set(_vm.copyPlaceholder, "endAt", $$v)
                    },
                    expression: "copyPlaceholder.endAt",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isConcept
          ? _c(
              "div",
              [
                _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    attrs: { title: "Uhrzeit" },
                  },
                  [_vm._v("access_time")]
                ),
                _vm._v(" "),
                _c("date-time", {
                  staticClass: "has-margin-right",
                  attrs: { "time-only": "" },
                  model: {
                    value: _vm.copyPlaceholder.startTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.copyPlaceholder, "startTime", $$v)
                    },
                    expression: "copyPlaceholder.startTime",
                  },
                }),
                _vm._v(" -\n            "),
                _c("date-time", {
                  staticClass: "has-margin-left",
                  attrs: { "time-only": "" },
                  model: {
                    value: _vm.copyPlaceholder.endTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.copyPlaceholder, "endTime", $$v)
                    },
                    expression: "copyPlaceholder.endTime",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", [
          _c(
            "i",
            { staticClass: "material-icons", attrs: { title: "Tageszeit" } },
            [_vm._v("access_time")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "select" },
            [
              _c("time-of-day-select", {
                model: {
                  value: _vm.copyPlaceholder.timeOfDay,
                  callback: function ($$v) {
                    _vm.$set(_vm.copyPlaceholder, "timeOfDay", $$v)
                  },
                  expression: "copyPlaceholder.timeOfDay",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.copyPlaceholder.type === "drive"
        ? _c("div", { staticClass: "is-160" }, [
            _c("div", { staticClass: "bp-row-form__title" }, [
              _vm._v("Leerfahrt"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("directions_bus"),
                ]),
                _vm._v(" "),
                _c(
                  "toggle-switch",
                  {
                    model: {
                      value: _vm.copyPlaceholder.emptyDrive,
                      callback: function ($$v) {
                        _vm.$set(_vm.copyPlaceholder, "emptyDrive", $$v)
                      },
                      expression: "copyPlaceholder.emptyDrive",
                    },
                  },
                  [
                    _c("option", { domProps: { value: false } }, [
                      _vm._v("nein"),
                    ]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: true } }, [_vm._v("ja")]),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isConcept
        ? _c("div", [
            _c("div", { staticClass: "bp-row-form__title" }, [
              _vm._v("Währung"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("multiselect", {
                  staticClass: "is-small",
                  attrs: {
                    options: _vm.potentialCurrencies
                      ? _vm.potentialCurrencies
                      : ["EUR"],
                    placeholder: "EUR",
                    "allow-empty": false,
                  },
                  model: {
                    value: _vm.copyPlaceholder.pricePerPerson.currency,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.copyPlaceholder.pricePerPerson,
                        "currency",
                        $$v
                      )
                    },
                    expression: "copyPlaceholder.pricePerPerson.currency",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isConcept
        ? _c("div", { staticClass: "is-flex-grow" }, [
            _c("div", { staticClass: "bp-row-form__title" }, [
              _vm._v("Eintritt & Gebühren"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("account_balance"),
                ]),
                _vm._v(" "),
                _c("input-price", {
                  attrs: {
                    prefix: _vm.copyPlaceholder.pricePerPerson.currency,
                  },
                  model: {
                    value: _vm.copyPlaceholder.pricePerPerson.amount,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.copyPlaceholder.pricePerPerson,
                        "amount",
                        $$v
                      )
                    },
                    expression: "copyPlaceholder.pricePerPerson.amount",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("account_balance"),
                ]),
                _vm._v(" "),
                _c("async-select", {
                  attrs: { api: "location_charges", multiple: "" },
                  on: {
                    create: function ($event) {
                      return _vm.$emit(
                        "newLocationCharges",
                        _vm.copyPlaceholder.predefinedRouteDetail
                      )
                    },
                  },
                  model: {
                    value: _vm.copyPlaceholder.locationCharges,
                    callback: function ($$v) {
                      _vm.$set(_vm.copyPlaceholder, "locationCharges", $$v)
                    },
                    expression: "copyPlaceholder.locationCharges",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.copyPlaceholder.type === "sport" && !_vm.isConcept
        ? _c("div", { staticClass: "is-flex-grow" }, [
            _c("div", { staticClass: "bp-row-form__title" }, [
              _vm._v("Streckenprofil"),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("i", { staticClass: "material-icons" }, [
                _vm._v("signal_cellular_4_bar"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.copyPlaceholder.additionalDescription,
                    expression: "copyPlaceholder.additionalDescription",
                  },
                ],
                staticClass: "input",
                attrs: { placeholder: "Streckenprofil", type: "text" },
                domProps: { value: _vm.copyPlaceholder.additionalDescription },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.copyPlaceholder,
                      "additionalDescription",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "is-160" }, [
              _c("i", {
                staticClass: "fa fa-signal",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "select" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.copyPlaceholder.difficulty,
                        expression: "copyPlaceholder.difficulty",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.copyPlaceholder,
                          "difficulty",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "simple" } }, [
                      _vm._v("einfach"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "medium" } }, [
                      _vm._v("mittel"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "hard" } }, [
                      _vm._v("schwer"),
                    ]),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }