<template>
    <route-form
        v-model                    = "copyPlaceholder"
        v-bind:isConcept           = "true"
        v-bind:limitedDestinations = "options.dayDestinations"
        v-bind:isTaggable          = "false"
        :passedOptions="options"
    >
        <button
            class="button is-close"
            v-on:click="$emit('cancel')"
            title="Verwerfen"
        ><i class="material-icons">close</i></button>

        <button
            class        = "button is-save is-primary"
            v-bind:class = "{ 'is-loading': isLoading }"
            v-on:click   = "save"
            title        = "Speichern"
        ><i class="material-icons">check</i></button>
    </route-form>
</template>



<script>
import { notifyError } from '@components/Notification';
import RouteForm from '@orders/placeholders/components/forms/Route';
import mixins from './mixins';
import moment from "moment/moment";


export default {
    mixins: [mixins],


    data: function () {
        return {
            copyPlaceholder: {
                routeDescription: '',
                pricePerPerson: {amount: '0', currency: 'EUR'},
                predefinedRouteDetail: null,
                timeOfDay: null,
                startTime: '00:00',
                endTime: '00:00',
                type: 'description',
                emptyDrive: false,

                clientOffer: {
                    status: 'included',
                    priceType: 'per_person',
                }
            }
        }
    },


    methods: {
        toSeconds(timeStr) {
            let [hours, minutes] = timeStr.split(':').map(Number);
            return hours * 3600 + minutes * 60;
        },

        save: function () {
            if (this.copyPlaceholder.predefinedRouteDetail){
                this.$emit('save', this.copyPlaceholder);

            } else {
                notifyError('Routenstichpunkte kann nicht leer sein!');
            }
        },


        onKeyUp: function (event) {
            if (event.keyCode === 13 &&
                this.placeholder === undefined    &&
                this.copyPlaceholder.predefinedRouteDetail
                ) {
                this.$nextTick(() => {
                    if (this.placeholderStringify === JSON.stringify(this.copyPlaceholder)) {
                        this.save();
                    }
                });
            }
        }
    },


    components: {
        RouteForm
    },

    watch: {
        'copyPlaceholder.startTime': function () {
            if (this.copyPlaceholder.predefinedRouteDetail && this.copyPlaceholder.startTime && this.copyPlaceholder.predefinedRouteDetail.duration) {
                let duration = this.toSeconds(this.copyPlaceholder.predefinedRouteDetail.duration);
                this.copyPlaceholder.endTime = moment(this.copyPlaceholder.startTime, 'HH:mm:ss').add(duration, 'seconds').format('HH:mm:ss');
            }
        },
    }
}
</script>
