var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "crud-table",
    {
      ref: "table",
      attrs: {
        api: "orders",
        url: "/orders",
        storeKey: `clients/${_vm.item.id}/orders`,
        filter: { "client.id": _vm.item.id },
        label: "Stammdaten",
        name: { singular: "reise", plural: "reisen" },
        columns: [
          { key: "orderNumber", label: "# ReiseNr" },
          "client.companyName",
          "groupName",
          { key: "startAt", filter: "startAt[after]" },
          { key: "endAt", filter: "startAt[before]" },
          { key: "trip.destinations", sortable: "trip.destinations.name" },
          { key: "user", filter: "user.id", sortable: "user.username" },
          {
            key: "mostUrgentTodo.dueDate",
            label: "Deadline",
            sortable: "mostUrgentDueDate",
          },
          {
            key: "currentStatus",
            filter: "currentStatus.status",
            sortable: "currentStatus.status",
          },
          { key: "hotelContingent", sortable: false },
          {
            key: "totalClientPayments.formatted",
            label: "Umsatz",
            sortable: false,
          },
        ],
      },
      on: { add: _vm.addNewOrder },
    },
    [
      _c("h2", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("REISEÜBERSICHT"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }